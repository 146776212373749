import * as React from "react";
import Layout from "../layouts/layout";
import { graphql, Link } from "gatsby";

const IndexPage = (props) => {
  return (
    <Layout pageTitle="Home Page">
      <main>
        <h1 className="text-6xl">Latest Posts</h1>
        <div className="flex flex-row flex-wrap justify-even">
          {props.data.allMarkdownRemark.edges.map((post) => (
            <div
              key={post.node.id}
              className="mt-4 flex justify-center px-4 py-2"
            >
              <div className="block p-6 rounded-lg shadow-lg bg-white max-w-smaller">
                <h3 className="text-gray-900 text-2xl leading-tight font-medium">
                  {post.node.frontmatter.title}
                </h3>
                <p className="text-xs">
                  Posted By: {post.node.frontmatter.author}
                </p>
                <p className="text-xs mb-4">
                  Date: {post.node.frontmatter.date}
                </p>
                <p className="text-gray-700 text-base mb-4">
                  {post.node.frontmatter.description}
                </p>
                <Link
                  to={post.node.frontmatter.path}
                  className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: {fields: frontmatter___number, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            number
            path
            date
            title
            description
            author
          }
        }
      }
    }
  }
`;

export default IndexPage;
